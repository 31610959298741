import './style.css'
import React from 'react';
import { slide as Menu } from 'react-burger-menu';

class MyBurgerMenu extends React.Component {
  showSettings(event) {
    event.preventDefault();
  }

  render() {
    return (
      <Menu width={280} right>
        <a className="menu-item" href="/">
          Home
        </a>
        <a className="menu-item" href="/privacy/privacy.html">
          Privacy Policy
        </a>
        <a className="menu-item" href="https://www.linkedin.com/in/saulburrows/" target="_blank">
          Linkedin
        </a>
      </Menu>
    );
  }
}


export default MyBurgerMenu;